import {
  parseISO,
  isSameDay,
  isSameWeek,
  startOfWeek,
  formatISO,
  addDays,
  isBefore,
  setHours,
  isWithinInterval,
  eachDayOfInterval,
} from "date-fns";
import I18n from "../components/shared/pia-i18n";
import { ERROR } from "../constants/action-types";
import { appsignal } from "../helper/appsignal";

export const ENTIRE_SCHEDLUE_MOBILE_POSITION_COLUMN_WIDTH = 116;
export const ENTIRE_SCHEDLUE_MOBILE_POSITION_COLUMN_MARGIN_WIDTH = 16;
export const ENTIRE_SCHEDULT_MOBILE_DAY_CELL_WIDTH = 41;

export const shifttypeFromTo = (shifttype, separator) => {
  const theSeparator = separator || ` ${I18n.t("frontend.shared.to_lc")} `;
  const startTime = shifttype.starttime.substring(11, 16);
  const endTime = shifttype.endtime.substring(11, 16);
  return `${startTime}${theSeparator}${endTime}`;
};

export const eventFromTo = (event) => {
  const startTime = event.starttime ? event.starttime.substring(11, 16) : "";
  const endTime = event.endtime ? event.endtime.substring(11, 16) : "";
  return `${startTime} ${
    endTime ? I18n.t("frontend.shared.to_lc") : ""
  } ${endTime}`;
};

export const getTimeFromDateTime = (dateTime) => {
  return dateTime.substring(11, 16);
};

export const assignmentsEditable = (job, admin, isLoadingBalances) => {
  return (
    job &&
    admin &&
    (job.state === "released_for_staff" || job.state === "locked_for_staff") &&
    !isLoadingBalances
  );
};

export const reduceErrors = (errorArray) => {
  return errorArray.reduce(function (acc, val) {
    if (val != undefined && val != "") {
      return acc + ", " + val;
    } else {
      return acc;
    }
  });
};

export const csrfToken = () => {
  return (
    (document.getElementsByName("csrf-token") &&
      document.getElementsByName("csrf-token")[0] &&
      document.getElementsByName("csrf-token")[0].content) ||
    ""
  );
};

export const dispatch_unknown_error = (dispatch, error) => {
  if (![401, 403].includes(error.response?.status)) {
    appsignal.sendError(error);
  }
  return dispatch({ type: ERROR, payload: getErrorMessages(error) });
};

const getErrorMessages = (error) => {
  return [401, 403].includes(error.response?.status)
    ? I18n.t("frontend.shared.unauthorized_error")
    : error.message;
};

export const selectPeriod = (periods, periodWeeks, week) => {
  const selectedPeriodWeek = getPeriodForWeek(periodWeeks, week);
  if (selectedPeriodWeek) {
    return periods.find((period) => period.id === selectedPeriodWeek.period_id);
  } else {
    const period = periods.find((period) => {
      return period.is_default == true;
    });
    return period;
  }
};

export const getPeriodForWeek = (periodWeeks, week) => {
  const sunday = parseISO(week.enddate);
  return periodWeeks.find((periodWeek) => {
    const periodWeekDate = parseISO(periodWeek.date);
    return isSameWeek(periodWeekDate, sunday, { weekStartsOn: 1 });
  });
};

export const createWeekState = (date, businessId, firstPossibleDate) => {
  const theDate = parseISO(date);
  const monday = startOfWeek(theDate, { weekStartsOn: 1 });
  if (firstPossibleDate) {
    return {
      startdate: formatISO(monday),
      enddate: formatISO(addDays(monday, 6)),
      firstPossibleDate: firstPossibleDate,
      businessId: businessId,
    };
  } else {
    return {
      startdate: formatISO(monday),
      enddate: formatISO(addDays(monday, 6)),
      businessId: businessId,
    };
  }
};

export const truncateString = (text, num, appendix = "...") => {
  if (text.length <= num) {
    return text;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return text.slice(0, num - appendix.length) + appendix;
};

export const pickTextColor = (bgColor) => {
  const lightColor = "#FFFFFF";
  const darkColor = "#576070";
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? lightColor : darkColor;
};

export const pickTextColor2 = (backgroundColor) => {
  const lightColor = "!text-white";
  const darkColor = "main-text-color";
  const color =
    backgroundColor &&
    backgroundColor.charAt(0) === "#" &&
    backgroundColor.length == 7
      ? backgroundColor.substring(1, 7)
      : "9cbed4";
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

export const positionGroupIsSelected = (selectedPositionGroups, teamId) => {
  return selectedPositionGroups.some((group) => {
    return group.id === teamId;
  });
};

export const selectedPositionGroupsToParam = (selectedPositionGroups) => {
  return selectedPositionGroups
    ? selectedPositionGroups.map((group) => group.id).join("_")
    : "";
};

export const safeAdd = (x, y) => {
  return parseFloat(x) + parseFloat(y);
};

export const sumAbsenceHours = (absenceHoursPerReason) => {
  return Object.keys(absenceHoursPerReason).reduce((acc, key) => {
    return acc + parseFloat(absenceHoursPerReason[key]);
  }, 0);
};

export const sumAnnualTargetHours = (dailyTargetHours) => {
  return Object.values(dailyTargetHours)
    .filter((dailyTargetHour) => {
      return !(dailyTargetHour === undefined || dailyTargetHour == null);
    })
    .reduce((acc, dailyTargetHour) => {
      return parseFloat(acc) + parseFloat(dailyTargetHour.target_hours);
    }, 0);
};

export const sumTargetWorkingHoursUntilEnddate = (
  absenceHoursPerReasonUntilNow,
  dailyTargetHours,
  enddate,
) => {
  const absenceHours = Object.keys(absenceHoursPerReasonUntilNow).reduce(
    (acc, key) => {
      return acc + parseFloat(absenceHoursPerReasonUntilNow[key]);
    },
    0,
  );
  const targetHours = Object.values(dailyTargetHours)
    .filter((dailyTargetHour) => {
      return !(
        dailyTargetHour === undefined ||
        dailyTargetHour == null ||
        isBefore(enddate, parseISO(dailyTargetHour.date))
      );
    })
    .reduce((acc, dailyTargetHour) => {
      return parseFloat(acc) + parseFloat(dailyTargetHour.target_hours);
    }, 0);
  return targetHours - absenceHours;
};

export const getTargetHours = (targetHours) => {
  if (targetHours === "0.0" || targetHours === "0") {
    return "";
  } else if (targetHours.slice(-2) === ".0") {
    return targetHours.slice(0, targetHours.indexOf(".0"));
  } else {
    return parseFloat(targetHours).toFixed(2);
  }
};

export const getMaxAssignmentsPerDay = (position) => {
  if (position && position.shift_assignments) {
    const groupedAssignments = position.shift_assignments.reduce(
      (acc, assignment) => ({
        ...acc,
        [assignment["date"]]: [...(acc[assignment["date"]] || []), assignment],
      }),
      {},
    );
    const arrayOfAmounts = Object.values(groupedAssignments).map(
      (assignments) => assignments.length,
    );
    const maxNumber = Math.max(...arrayOfAmounts);
    return Number.isNaN(maxNumber) && arrayOfAmounts.length > 0
      ? arrayOfAmounts[0]
      : arrayOfAmounts.length > 0
        ? maxNumber
        : 0;
  } else {
    return 0;
  }
};

export const deleteFirst = (array, value) => {
  var index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

export const calculateAssignmentAlignment = (assignments) => {
  if (
    assignments.length == 1 &&
    assignments[0].shifttype.working_duration <= 5
  ) {
    const starttime = parseISO(assignments[0].shifttype.starttime);
    const midday = setHours(starttime, 12);
    if (isBefore(midday, starttime)) {
      return {
        justifyContent: "flex-end",
      };
    } else {
      return {
        justifyContent: "flex-start",
      };
    }
  } else {
    return {};
  }
};

export const isTimeView = (layout) => {
  return layout === "time";
};

export const eventsForDay = (date, events = []) => {
  return events.filter((event) => {
    return isWithinInterval(date, {
      start: parseISO(event.startdate),
      end: parseISO(event.enddate),
    });
  });
};

export const getMaxEventsPerDay = (events, feastDays, startdate, enddate) => {
  let maxAmountPerDay = 0;
  const result = eachDayOfInterval({
    start: startdate,
    end: enddate,
  });
  result.forEach((date) => {
    const amountOfEvents = eventsForDay(date, events).length;
    const amountOfFeastDays = feastDays.filter((feastDay) => {
      return isSameDay(parseISO(feastDay.date), date);
    }).length;
    if (amountOfEvents + amountOfFeastDays > maxAmountPerDay) {
      maxAmountPerDay = amountOfEvents + amountOfFeastDays;
    }
  });
  return maxAmountPerDay;
};

export const isGroupRow = ({
  currentPositionBusinessId,
  jobBusinessId,
  lastPositionGroupId,
  currentPositionGroupId,
  lastBusinessId,
}) => {
  return (
    (currentPositionBusinessId === jobBusinessId &&
      lastPositionGroupId !== currentPositionGroupId) ||
    (currentPositionBusinessId !== jobBusinessId &&
      lastBusinessId !== currentPositionBusinessId)
  );
};

export const getEachDayInJob = (job) => {
  const startDate = parseISO(job.startdate);
  const endDate = parseISO(job.enddate);
  return eachDayOfInterval({ start: startDate, end: endDate });
};
